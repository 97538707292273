import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {
  constructor(
    private _permissionCheckerService: PermissionCheckerService,
    private _appSessionService: AppSessionService
  ) { }

  //   STUDENT SIDE NAVIGATIONS
  getMenu(): AppMenu {
        //   ADMIN SIDE NAVIGATIONS
      return new AppMenu('MainMenu', 'MainMenu', [
        new AppMenuItem(
          'Dashboard',
          'Pages.Administration.Host.Dashboard',
          'fa-duotone fa-house',
          '/app/admin/hostDashboard'
        ),
        new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'fa-duotone fa-house', '/app/main/dashboard'),
        new AppMenuItem('Tenants', 'Pages.Tenants', 'pi pi-users', '/app/admin/tenants'),

        //new AppMenuItem('ClassGeoLocation', 'Pages.ClassGeoLocation', 'flaticon-more', '/app/main/student/classGeoLocation'),

        // new AppMenuItem('GpsAttendances', 'Pages.GpsAttendances', 'flaticon-more', '/app/main/student/gpsAttendances'),

        new AppMenuItem('JobCompanies', 'Pages.JobCompanies', 'fa-duotone fa-building', '/app/main/cms/jobCompanies'),

        new AppMenuItem('Jobs', 'Pages.Jobs', 'fa-duotone fa-briefcase', '/app/main/cms/jobs'),

            new AppMenuItem('CompanyContacts', 'Pages.CompanyContacts', 'fa-duotone fa-address-book', '/app/main/cms/companyContacts'),

            // new AppMenuItem('AdvertisementCompanies', 'Pages.AdvertisementCompanies', 'fa-duotone fa-tv-retro', '/app/main/cms/advertisementCompanies'),
            new AppMenuItem('JobCategories', 'Pages.JobCategories', 'fa-duotone fa-square-list', '/app/main/cms/jobCategories'),

            new AppMenuItem('JobApplication', 'Pages.JobApplication', 'fa-duotone fa-memo-pad', '/app/main/cms/jobApplication'),

            new AppMenuItem('TeamMembers', 'Pages.TeamMembers', 'fa-duotone fa-users', '/app/main/cms/teammember'),

            new AppMenuItem('Branch', 'Pages.Branch', 'fa-duotone fa-code-branch', '/app/main/office/branch'),
            new AppMenuItem('Tax Rate Category', 'Pages.Branch', 'fa-duotone fa-flux-capacitor', '/app/main/cms/taxRateCategory'),
             new AppMenuItem('Tax Rate', 'Pages.Branch', 'fa-duotone fa-cash-register', '/app/main/cms/taxRate'),

             new AppMenuItem('Wada Patras', 'Pages.Branch', 'fa-duotone fa-house-tree', '/app/main/cms/wadaPatras'),

            new AppMenuItem('BranchStaffs', 'Pages.BranchStaffs', 'fa-duotone fa-users-medical', '/app/main/office/branchStaffs'),

            new AppMenuItem('Services', 'Pages.Services', 'fa-duotone fa-gears', '/app/main/office/services'),

            new AppMenuItem('ServiceDownload', 'Pages.ServiceDownload', 'fa-duotone fa-hand-holding-box', '/app/main/office/serviceDownload'),

            new AppMenuItem('Budget', 'Pages.Budget', 'fa-duotone fa-file-invoice-dollar', '/app/main/office/budget'),

            new AppMenuItem('Rule', 'Pages.Rule', 'fa-duotone fa-list-check', '/app/main/office/rule'),

            new AppMenuItem('ScrollNews', 'Pages.ScrollNews', 'fa-duotone fa-newspaper', '/app/main/office/scrollNews'),
             new AppMenuItem('Editions', 'Pages.Editions', 'pi pi-book', '/app/admin/editions'),



            new AppMenuItem('Advertisement', 'Pages.Advertisements', 'fa-duotone fa-newspaper', '/app/main/cms/Advertisements'),
            new AppMenuItem('Youtube Links', 'Pages.Branch', 'fa-duotone fa-code-branch', '/app/main/office/youtube-link'),
            new AppMenuItem('Notice', 'Pages.Notices', 'fa-duotone fa-bell-exclamation', '/app/main/cms/Notices'),

            new AppMenuItem('Pages', 'Pages.CmsPages', 'fa-duotone fa-page', '/app/main/cms/page'),
            new AppMenuItem(
              'Banner Categories',
              'Pages.BannerCategories',
              'fa-duotone fa-layer-group',
              '/app/main/cms/bannercategory'
            ),
            new AppMenuItem('Banners', 'Pages.Banners', 'fa-duotone fa-ribbon', '/app/main/cms/banner'),
            new AppMenuItem(
              'Blog Categories',
              'Pages.BlogCategories',
              'fa-duotone fa-layer-group',
              '/app/main/cms/blogcategory'
            ),
            new AppMenuItem('Blogs', 'Pages.Blogs', 'fa-duotone fa-blog', '/app/main/cms/blog'),
            new AppMenuItem('Events', 'Pages.CmsEvents', 'fa-duotone fa-calendar', '/app/main/cms/event'),
            new AppMenuItem(
              'Facility',
              'Pages.CmsFacilities',
              'fa-duotone fa-house-medical',
              '/app/main/cms/Facility'
            ),
            new AppMenuItem(
              'Download',
              'Pages.CmsDownloads',
              'fa-duotone fa-circle-down',
              '/app/main/cms/Download'
            ),
            new AppMenuItem(
              'Gallery Categories',
              'Pages.GalleryCategories',
              'fa-duotone fa-image-polaroid',
              '/app/main/cms/gallerycategories'
            ),
            new AppMenuItem('Gallery', 'Pages.Gallery', 'fa-duotone fa-image', '/app/main/cms/gallery'),
            // new AppMenuItem('TeamMembers', 'Pages.TeamMembers', 'fa-duotone fa-users', '/app/main/cms/teammember'),
            new AppMenuItem('Courses', 'Pages.CmsCourse', 'fa-duotone fa-books', '/app/main/cms/course'),
            new AppMenuItem('Careers', 'Pages.CmsCourse', 'fa-duotone fa-vector-circle', '/app/main/cms/careers'),
            new AppMenuItem('Video Categories', '', 'fa-duotone fa-speaker', '/app/main/cms/CmsVideoCategories'),
            new AppMenuItem('Video', 'Pages.CmsVideos', 'fa-duotone fa-speaker', '/app/main/cms/CmsVideo'),
            new AppMenuItem(
              'Testimonials',
              'Pages.CmsCourse',
              'fa-duotone fa-speaker',
              '/app/main/cms/testimonials'
            ),


        new AppMenuItem(
          'Setting',
          '',
          'fa-duotone fa-gear',
          '',
          [],
          [
            new AppMenuItem('Company', 'Pages.Branchs', 'fa-duotone fa-buildings', '/app/main/controlPanel/branchs'),
            new AppMenuItem('Address', 'Pages.Branchs', 'fa-duotone fa-location-dot', '/app/main/controlPanel/address'),
            new AppMenuItem(
              'Notifications',
              '',
              'flaticon-alarm',
              '',
              [],
              [
                  new AppMenuItem(
                      'Inbox',
                      '',
                      'flaticon-mail-1',
                      '/app/notifications'
                  ),
                  new AppMenuItem(
                      'MassNotifications',
                      'Pages.Administration.MassNotification',
                      'flaticon-paper-plane',
                      '/app/admin/mass-notifications'
                  ) ,
              ]
          ),
            new AppMenuItem(
              'OrganizationUnits',
              'Pages.Administration.OrganizationUnits',
              'fa-duotone fa-house-blank',
              '/app/admin/organization-units'
            ),
            new AppMenuItem('Roles', 'Pages.Administration.Roles', 'fa-duotone fa-person', '/app/admin/roles'),
            new AppMenuItem('Users', 'Pages.Administration.Users', 'fa-duotone fa-users', '/app/admin/users'),
            new AppMenuItem(
              'Languages',
              'Pages.Administration.Languages',
              'fa-duotone fa-language',
              '/app/admin/languages',
              ['/app/admin/languages/{name}/texts']
            ),
            new AppMenuItem(
              'AuditLogs',
              'Pages.Administration.AuditLogs',
              'fa-duotone fa-list-check',
              '/app/admin/auditLogs'
            ),
            new AppMenuItem(
              'Maintenance',
              'Pages.Administration.Host.Maintenance',
              'fa-duotone fa-sliders',
              '/app/admin/maintenance'
            ),
            new AppMenuItem(
              'Subscription',
              'Pages.Administration.Tenant.SubscriptionManagement',
              'fa-duotone fa-hand-pointer',
              '/app/admin/subscription-management'
            ),
            new AppMenuItem(
              'VisualSettings',
              'Pages.Administration.UiCustomization',
              'fa-duotone fa-eye',
              '/app/admin/ui-customization'
            ),
            new AppMenuItem(
              'WebhookSubscriptions',
              'Pages.Administration.WebhookSubscription',
              'fa-duotone fa-bell',
              '/app/admin/webhook-subscriptions'
            ),
            new AppMenuItem(
              'DynamicProperties',
              'Pages.Administration.DynamicProperties',
              'fa-duotone fa-star',
              '/app/admin/dynamic-property'
            ),
            new AppMenuItem(
              'Settings',
              'Pages.Administration.Host.Settings',
              'fa-duotone fa-gear',
              '/app/admin/hostSettings'
            ),
            new AppMenuItem(
              'Settings',
              'Pages.Administration.Tenant.Settings',
              'fa-duotone fa-gear',
              '/app/admin/tenantSettings'
            ),
          ]
        ),
      ]);
    }


  checkChildMenuItemPermission(menuItem): boolean {
    for (let i = 0; i < menuItem.items.length; i++) {
      let subMenuItem = menuItem.items[i];

      if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
        if (subMenuItem.route) {
          return true;
        }
      } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
        return true;
      }

      if (subMenuItem.items && subMenuItem.items.length) {
        let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
        if (isAnyChildItemActive) {
          return true;
        }
      }
    }
    return false;
  }

  showMenuItem(menuItem: AppMenuItem): boolean {
    if (
      menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
      this._appSessionService.tenant &&
      !this._appSessionService.tenant.edition
    ) {
      return false;
    }

    let hideMenuItem = false;

    if (menuItem.requiresAuthentication && !this._appSessionService.user) {
      hideMenuItem = true;
    }

    if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
      hideMenuItem = true;
    }

    if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
      if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
        hideMenuItem = true;
      }
    }

    if (!hideMenuItem && menuItem.items && menuItem.items.length) {
      return this.checkChildMenuItemPermission(menuItem);
    }

    return !hideMenuItem;
  }

  /**
   * Returns all menu items recursively
   */
  getAllMenuItems(): AppMenuItem[] {
    let menu = this.getMenu();
    let allMenuItems: AppMenuItem[] = [];
    menu.items.forEach((menuItem) => {
      allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
    });

    return allMenuItems;
  }

  private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
    if (!menuItem.items) {
      return [menuItem];
    }

    let menuItems = [menuItem];
    menuItem.items.forEach((subMenu) => {
      menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
    });

    return menuItems;
  }
}
